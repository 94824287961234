import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { filteredMainRoutes } from './routes/mainRoutes'

import MainLayout from './layouts/MainLayout'

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          {filteredMainRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path as string}
              element={route.element}
            />
          ))}
        </Route>
      </Routes>
    </Router>
  )
}

export default App
