import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from './AppBar'
import SwipeableTemporaryDrawer from './Drawer'

const MainLayout = () => {
  const [open, setOpen] = useState(false)
  const setIsOpen = () => {
    setOpen(!open)
  }
  return (
    <div>
      <SwipeableTemporaryDrawer isOpen={open} setOpen={setIsOpen} />
      <header>
        <AppBar setOpen={setIsOpen} />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <p>&copy; 2023 My App</p>
      </footer>
    </div>
  )
}

export default MainLayout
