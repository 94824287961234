import NotFound from '../components/NotFound'
import { isAuthenticated } from '../utils/auth'
import { RouteObject } from 'react-router-dom'
import React, { lazy } from 'react'
import Loadable from '../components/common/Loadable'

const Home = Loadable(lazy(() => import('../components/Home')))

export const mainRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export const filteredMainRoutes = isAuthenticated()
  ? mainRoutes
  : mainRoutes.filter(
      (route) => !route.path || !route.path.startsWith('/dashboard')
    )
